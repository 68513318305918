import { Controller } from "@hotwired/stimulus";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
export default class extends Controller {
  static targets = ["playlists", "audioFiles"];

  connect() {
    this.renderPlaylists();
    setTimeout(() => {
      const firstPlaylistButton = this.playlistsTarget.querySelector("p");
      if (firstPlaylistButton) {
        firstPlaylistButton.click();
      }
    }, 0);  // setTimeout pour click sur le bouton de la première playlist après le render, en vrai ça peut être refacto avec un appel à la fonction fetchAudioFiles plutôt
  }

  async fetchAudioFiles(playlistId, clickedButton) {
    const apiKey = "rk8waUp1QNK4uamHmG659n8K4NVeYcHuu7KVFeq6cgSxN4uccDi000brmHlEABfq";

    try {
      const response = await fetch(
        `https://api.soundstripe.com/v1/playlists/${playlistId}?include=songs.audio_files&page[size]=15&page[number]=1`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch audio files");
      }

      const data = await response.json();
      const songsData = data.included.filter((item) => item.type === "songs");
      const audioFilesData = data.included.filter(
        (item) => item.type === "audio_files"
      );
      const audioFilesWithTitles = audioFilesData.map((audioFile) => {
        const song = songsData.find(
          (song) => song.id === audioFile.attributes.song_id
        );
        return {
          ...audioFile,
          songTitle: song ? song.attributes.title : "Unknown Title",
          url: audioFile.attributes.versions.mp3,
        };
      });

      this.displayAudioFiles(audioFilesWithTitles);

      // Gestion de la sélection visuelle des playlists
      this.updatePlaylistStyles(clickedButton);

    } catch (error) {
      console.error("Error fetching audio files:", error);
    }
  }

  displayAudioFiles(audioFiles) {
    const audioFilesContainer = this.audioFilesTarget;
    audioFilesContainer.innerHTML = ""; // clear container

    audioFiles.forEach((audioFile) => {
      const audioItem = document.createElement("div");
      audioItem.classList.add("flex", "justify-between", "items-center", "my-4", "border-b", "border-gray-200", "pb-4");

      const infoContainer = document.createElement("div");
      infoContainer.classList.add("flex", "gap-3", "items-center");

      // radio input pour sélectionner la chanson
      const radioBtn = document.createElement("input");
      radioBtn.type = "radio"
      radioBtn.name = "song-select";
      radioBtn.classList.add("h-4", "w-4", "text-indigo-600", "border-gray-300", "focus:ring-indigo-500");

      radioBtn.addEventListener("change", () => {
        if (currentRegion) {
          document.getElementById("audio-start").value = currentRegion.start;
          document.getElementById("audio-end").value = currentRegion.end;
          document.getElementById("audio-url").value = audioFile.url;

          // Pour débogage
          console.log(`Start: ${currentRegion.start}, End: ${currentRegion.end}, URL: ${audioFile.url}`);
        } else {
          console.log("Aucune région sélectionnée");
        }
      });

      // Titre de la chanson
      const title = document.createElement("p");
      title.classList.add("text-gray-900", "truncate", "w-40");
      title.innerText = audioFile.songTitle;

      infoContainer.appendChild(radioBtn);
      infoContainer.appendChild(title);

      const waveformContainer = document.createElement("div");
      waveformContainer.style.width = "500px";
      waveformContainer.id = `waveform-${audioFile.id}`;

      const plugins = RegionsPlugin.create({});
      const ws = WaveSurfer.create({
        container: waveformContainer,
        waveColor: 'rgb(67,56,202)',
        progressColor: 'rgb(67,56,202)',
        height: 80,
        backend: 'MediaElement',
        url: audioFile.url,
        pixelRatio: 0,
        plugins: [plugins],
      });

      let currentRegion = null;
      let isPlaying = false;

      // Boutons de contrôle sous l'onde sonore
      const controlsContainer = document.createElement("div");
      controlsContainer.classList.add("flex", "gap-4");

      // Bouton Play/Pause pour la région sélectionnée
      const playRegionBtn = document.createElement("div");
      playRegionBtn.classList.add("bg-brand_main", "text-white", "py-2", "px-2", "rounded", "text-sm");
      playRegionBtn.innerText = "Play Region";

      playRegionBtn.onclick = () => {
        if (currentRegion) {
          if (!isPlaying) {
            ws.seekTo(currentRegion.start / ws.getDuration());
            ws.play(currentRegion.start, currentRegion.end);
            playRegionBtn.innerText = "Pause Region";
          } else {
            ws.pause();
            playRegionBtn.innerText = "Play Region";
          }
          isPlaying = !isPlaying;
        } else {
          console.error("Aucune région sélectionnée");
        }
      };

      // Bouton Play Full Audio
      const playFullAudioBtn = document.createElement("div");
      playFullAudioBtn.classList.add("bg-brand_main", "text-white", "py-2", "px-2", "rounded", "text-sm");
      playFullAudioBtn.innerText = "Play Full Audio";
      playFullAudioBtn.onclick = () => ws.playPause();

      // Ajouter tous les éléments dans le container de la chanson
      controlsContainer.appendChild(playRegionBtn);
      controlsContainer.appendChild(playFullAudioBtn);

      // Ajouter tous les éléments dans le container principal
      audioItem.appendChild(infoContainer);
      audioItem.appendChild(waveformContainer);
      audioItem.appendChild(controlsContainer);
      audioFilesContainer.appendChild(audioItem);

      // Initialiser la région après que le lecteur est prêt
      ws.on("ready", () => {
        currentRegion = ws.plugins[0].addRegion({
          start: 0,
          end: 15,
          color: 'rgb(165,180,252,0.4) ',
          drag: true,
          resize: true,
        });
      });

      // Assurer que l'audio s'arrête à la fin de la région
      ws.on("audioprocess", (time) => {
        if (currentRegion && time > currentRegion.end) {
          ws.pause();
          ws.seekTo(currentRegion.start / ws.getDuration());
          playRegionBtn.innerText = "Play Region";
          isPlaying = false;
        }
      });
    });
  }

  renderPlaylists() {
    const playlistMap = {
      "The Vocal Vault": "9821",
      "Epic Score": "97299",
      "Adrenaline Junkie": "97298",
      Quirky: "9",
      "Young and Free": "868",
      "Rock Rock Rock": "8006",
      "Holiday Vocals": "76589",
      "Fall Vibes": "75",
      Chill: "7457",
      "Indie Radio": "73",
    };

    const playlistsContainer = this.playlistsTarget;

    // clear container before rendering, to avoid duplicates when going back and forth between index and new
    playlistsContainer.innerHTML = "";

    Object.keys(playlistMap).forEach(playlist => {
      const playlistId = playlistMap[playlist];

      const button = document.createElement("p");
      button.classList.add("text-xs", "border", "border-brand_main", "rounded-full", "px-2.5", "py-1", "cursor-pointer", "text-brand_main", "bg-white");

      button.innerText = playlist;
      button.addEventListener("click", () => {
        this.fetchAudioFiles(playlistId, button);
      });

      playlistsContainer.appendChild(button);
    });
  }

  updatePlaylistStyles(clickedButton) {
    const allButtons = this.playlistsTarget.querySelectorAll("p");

    allButtons.forEach(button => {
      if (button === clickedButton) {
        button.classList.add("bg-brand_main", "text-white");
        button.classList.remove("bg-white", "text-brand_main");
      } else {
        button.classList.add("bg-white", "text-brand_main");
        button.classList.remove("bg-brand_main", "text-white");
      }
    });
  }
}
