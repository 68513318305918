import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dynamicSequencesContainer", "sequence", "addButton", "submitButton"];

  connect() {
    this.dataArray = JSON.parse(this.data.get("props"));
    this.lengthVariants = JSON.parse(this.data.get("length"));
    this.dynamicPosition = this.getDynamicPosition();
    this.addPlusButton();
    this.checkFormCompletion();  // Valide le formulaire dès le chargement de la page
    this.watchInputs();  // Ajoute l'écoute des changements sur les inputs
  }

  getDynamicPosition() {
    const dynamicSequences = this.dataArray.filter(seq => seq.type === "dynamic");
    return dynamicSequences.length > 0 ? dynamicSequences[0].position : null;
  }

  addPlusButton(){
    const lastDynamic = this.dynamicSequencesContainerTarget.querySelectorAll("[data-sequence-type='dynamic']");
    
    if (lastDynamic.length > 0) {
      const lastElement = lastDynamic[lastDynamic.length - 1];

      const existingButton = this.dynamicSequencesContainerTarget.querySelector('.add-sequence-btn');
      if (existingButton) existingButton.remove();

      const plusButton = document.createElement("button");
      plusButton.textContent = "Ajouter une séquence +";
      plusButton.type = "button";
      plusButton.className = "btn-brand-primary w-fit ml-auto add-sequence-btn";

      plusButton.addEventListener("click", (e) => this.addDynamicSequence(e));
      lastElement.insertAdjacentElement("afterend", plusButton);
    }
  }

  addDynamicSequence(event) {
    event.preventDefault();
  
    const currentDynamicCount = this.dataArray.filter(seq => seq.type === "dynamic").length;
    const maxDynamicCount = Math.max(...this.lengthVariants);
  
    if (currentDynamicCount >= maxDynamicCount) {
      alert("Vous avez atteint le nombre maximum de séquences dynamiques.");
      return;
    }
  
    const lastDynamicSequence = this.dataArray.find(seq => seq.type === "dynamic");

    const newDynamicSequence = { ...lastDynamicSequence };
  
    const lastPosition = Math.max(...this.dataArray.map(seq => parseInt(seq.position) || 0));
    newDynamicSequence.position = lastPosition + 1; // toujours ajouter 1 à la position la plus élevée
    newDynamicSequence.type = 'dynamic'; 
    delete newDynamicSequence.index;
  
    this.dataArray.push(newDynamicSequence);
  
    // recalcule les positions pour toutes les sequences
    this.dataArray = this.dataArray.map((seq, i) => {
      seq.position = i + 1; // positionnement propre basé sur l'index
      return seq;
    });
  
    // rendre la nouvelle séquence
    this.renderNewSequence(newDynamicSequence);
  
    // replacer le bouton 
    this.addPlusButton();
    
    // Recheck form validation after adding new sequence
    this.checkFormCompletion();
  }
  
  removeDynamicSequence(event) {
    event.preventDefault();

    const currentDynamicCount = this.dataArray.filter(seq => seq.type === "dynamic").length;
    const minDynamicCount = Math.min(...this.lengthVariants);

    if (currentDynamicCount <= minDynamicCount) {
      alert("Vous avez atteint le nombre minimum de séquences dynamiques.");
      return;
    }

    const button = event.target;
    const sequenceDiv = button.closest('.sequence-div');
    const index = Array.from(this.dynamicSequencesContainerTarget.children).indexOf(sequenceDiv);

    // Supprimer de l'array dataArray
    this.dataArray.splice(index, 1);

    // Supprimer la div correspondante
    sequenceDiv.remove();

    this.addPlusButton();

    // Recheck form validation after removing sequence
    this.checkFormCompletion();
  }

  renderNewSequence(sequence) {
    const index = this.dataArray.indexOf(sequence); 
    const sequenceDiv = document.createElement("div");
    sequenceDiv.className = "sequence-div flex flex-col gap-6 mt-4";
    sequenceDiv.dataset.sequenceType = sequence.type || "dynamic"; 
  
    sequenceDiv.innerHTML = `
      <div class="flex flex-col gap-6">
        <h3 class="my-3 font-semibold">Séquence ${sequence.position}</h3>
        <div class="flex gap-6">
          <div class="">
            ${sequence.position ? `<img src="${sequence.preview}" alt="" class="rounded">` : ""}
          </div>
          <div class="w-full flex flex-col gap-6">
            ${Object.keys(sequence).filter(key => key !== "preview" && key !== "type" && key !== "position" && key !== "preview_dynamic_sequences").map(key => `
              <div class="mb-2">
                ${key.startsWith('image') ? 
                  `<div data-controller="cloudinary-uploader" data-cloudinary-uploader-props='${JSON.stringify({ media: [], field_name: `creator_video[all_sequences][${index}][${key}]` })}'></div>` :
                  `<input type="text" name="creator_video[all_sequences][${index}][${key}]" value="${sequence[key]}" class="form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm bg-gray-50">`
                }
              </div>
            `).join('')}
            <input type="hidden" name="creator_video[all_sequences][${index}][type]" value="dynamic">
          </div>
        </div>
        ${sequence.type === "dynamic" ? 
        `<button class="btn-delete w-fit remove-sequence-btn" data-index="${index}">Supprimer</button>` : ""}
      </div>
    `;
  
    const addButton = this.dynamicSequencesContainerTarget.querySelector(".add-sequence-btn");
    addButton.insertAdjacentElement("beforebegin", sequenceDiv);
    sequenceDiv.querySelector(".remove-sequence-btn").addEventListener("click", (e) => this.removeDynamicSequence(e));
  }

  checkFormCompletion() {
    const form = document.getElementById("creator-video-form");
    const submitButton = form.querySelector("input[type='submit']");

    let isValid = true;

    // Sélectionner tous les inputs texte, couleur, hidden, select et les images Cloudinary
    const inputs = form.querySelectorAll("input[type='text'], input[type='color'], input[type='hidden'][name*='[image]'], select");

    inputs.forEach(input => {
      if (input.value.trim() === "") {
        isValid = false;
      }
    });

    // Désactive ou active le bouton en fonction de la validité
    if (isValid) {
      submitButton.disabled = false;
      submitButton.classList.remove("bg-gray-500", "cursor-not-allowed");
      submitButton.classList.add("btn-brand-primary");
    } else {
      submitButton.disabled = true;
      submitButton.classList.remove("btn-brand-primary");
      submitButton.classList.add("bg-gray-500", "cursor-not-allowed");
    }
  }

  watchInputs() {
    const form = document.getElementById("creator-video-form");
    const inputs = form.querySelectorAll("input[type='text'], input[type='color'], input[type='hidden'], select");

    // Ajoute un écouteur d'événements sur tous les inputs pour vérifier le formulaire à chaque changement
    inputs.forEach(input => {
      input.addEventListener("input", () => this.checkFormCompletion());
    });
  }
}